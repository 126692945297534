// import {
//   faGithub,
//   faLinkedin,
//   faTwitter,
// } from "@fortawesome/free-brands-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import "./home.css";
// const Home = () => {
//   return (
//     <div className="bigDiv">
//       <div className="innerDiv">
//         <img src="/Images/opic.png" className="pic" />
//         <div className="textNextToIMG">
//           <div className="big">Omar Abdel Aziz</div>
//           <div className="small">
//             Incoming University of Washington Computer Science student.
//             Insterested in Software Developement, Engeneering, Full stack
//             development, Computational Biology, and ML/AI
//           </div>
// <div className="buttons">
//   <button
//     className="resumeButton"
//     onClick={() => {
//       window.open(
//         "https://drive.google.com/file/d/1Vv-2I12vYOgeX81ounJBPrDdt8JrYFM4/view?usp=sharing",
//         "_blank"
//       );
//     }}
//   >
//     resume
//   </button>

//   <button
//     onClick={() => {
//       window.open(
//         "https://www.linkedin.com/in/omar-abdel-aziz-b311b9225/",
//         "_blank"
//       );
//     }}
//   >
//     <FontAwesomeIcon icon={faLinkedin} />
//   </button>
//   <button
//     onClick={() => {
//       window.open("https://github.com/omar-abdel-aziz", "_blank");
//     }}
//   >
//     <FontAwesomeIcon icon={faGithub} />
//   </button>

//   <button
//     onClick={() => {
//       window.open("https://twitter.com/Abdel_Aziz_Omar", "_blank");
//     }}
//   >
//     <FontAwesomeIcon icon={faTwitter} />
//   </button>
// </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Home;
import { gsap } from "gsap";
import Titlepage from "./Components/Titlepage";
import "./home.css";
import Buttons from "./Components/Buttons";
import Projects from "./Components/Projects";
import Information from "./Components/Information";
const Home = () => {
  return (
    <div className="HomePage">
      <Titlepage />
      <Buttons />
      <Projects />
      {/* <Information /> */}
    </div>
  );
};

export default Home;
