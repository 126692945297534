import React from "react";
import { gsap } from "gsap";
import "./Titlepage.css";
const Titlepage = () => {
  return (
    <div className="T-page">
      <div className="P-container">
        <img src={require("../pics/opic.png")} className="pic" />
      </div>
      <div className="text">
        <div className="Name">Omar Abdel Aziz</div>
        <div className="T-description">
          <ul>
            <li>Student at Paul Allen School of CSE</li>
            <li>
              <strong>Software Development/ Engineering</strong>
            </li>

            <li>
              <strong>Full Stack Development</strong>
            </li>
            <li>
              <strong>Computational Biology</strong>
            </li>
            <li>
              <strong>
                Interested in Machine Learning and Artificial Intelligence
              </strong>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Titlepage;
