import React, { useState } from "react";
import Modal from "react-modal";

import "./Projects.css";
const Projects = () => {
  const [modal1IsOpen, setModal1IsOpen] = useState(false);
  const [modal2IsOpen, setModal2IsOpen] = useState(false);
  const [modal3IsOpen, setModal3IsOpen] = useState(false);

  const openModal = (num) => {
    if (num === 1) {
      setModal1IsOpen(!modal1IsOpen);
    } else if (num === 2) {
      setModal2IsOpen(!modal2IsOpen);
    } else if (num === 3) {
      setModal3IsOpen(!modal3IsOpen);
    }
  };

  return (
    <div className="Projects">
      <div className="Weather-App" onClick={() => openModal(1)}>
        <div className="Info">
          <img src={require("../pics/QC.png")} />
          <div className="wApp">Quran Connect</div>
        </div>
        <Modal isOpen={modal1IsOpen} className="myStyledModal">
          <img src={require("../pics/QC.png")} />
          <div className="wDescription">
            <div>
              <h2>Founding Member/Software Developer at Quran Connect</h2>
              <p>
                <strong>Duration:</strong> Sep 2020 - Jul 2022 (1 year 11
                months)
              </p>
              <p>
                <strong>Location:</strong> Seattle, Washington, United States
              </p>
              <p>
                As a founding member and software developer at Quran Connect, I
                took the lead in developing an application designed to alleviate
                the administrative burden from teachers and facilitate effective
                communication between teachers and parents. This application is
                now in use across multiple Muslim institutions in the Puget
                Sound region.
              </p>
              <h3>Key Technologies:</h3>
              <ul>
                <li>React Native</li>
                <li>JavaScript</li>
                <li>HTML/CSS</li>
                <li>Firebase</li>
              </ul>
              <p>
                The application showcases my abilities in React Native,
                JavaScript, HTML/CSS, Firebase, and Test Automation,
                highlighting my expertise in creating practical and efficient
                digital solutions.
              </p>
              <button
                onClick={() => {
                  window.open(
                    "https://www.linkedin.com/company/quran-connect/",
                    "_blank"
                  );
                }}
                className="website-button"
              >
                Visit Quran Connect LinkedIN
              </button>
            </div>
          </div>
          <button
            className="closeModalButton"
            onClick={() => setModal1IsOpen(false)}
          >
            Close Modal
          </button>
        </Modal>
      </div>

      <div className="Weather-App" onClick={() => openModal(2)}>
        <div className="Info">
          <img src={require("../pics/code.png")} />
          <div className="wApp">Websites</div>
        </div>
        <Modal isOpen={modal2IsOpen} className="myStyledModal">
          <img src={require("../pics/quran1.png")} />
          <div className="wDescription">
            <div>
              <h2>Quran Content Website</h2>
              <p>
                As part of my projects, I have developed a comprehensive website
                featuring the contents of the Holy Quran. This digital platform
                highlights advanced API utilization, robust data organization,
                and an aesthetically pleasing, user-friendly interface.
              </p>
              <h3>Key Features:</h3>
              <ul>
                <li>
                  <strong>Audio Playback:</strong> Every chapter and verse of
                  the Quran can be listened to, aiding understanding and
                  pronunciation.
                </li>
                <li>
                  <strong>Translations:</strong> To ensure accessibility and
                  comprehension, translations are provided for each verse.
                </li>
                <li>
                  <strong>Arabic Text:</strong> The original Arabic text of the
                  Quran is displayed for every chapter and verse, maintaining
                  the authentic essence of the holy scripture.
                </li>
              </ul>
              <p>
                This project underscores my capabilities in handling complex
                data, integrating APIs, and creating user-oriented digital
                solutions. A particularly notable aspect of this project is the
                adept manipulation and organization of data, as well as the
                meticulous attention to detail in the display and content
                arrangement. This demonstrates my proficiency in data management
                and presentation.
              </p>
            </div>
            <button
              onClick={() => {
                window.open("https://quran-reccomend.web.app/", "_blank");
              }}
              className="website-button"
            >
              Visit Quran Reccomend Website
            </button>
            <div>----------------------------------------------</div>
            <div>
              <img src={require("../pics/ww.png")} />
              <h2>Weather Application Development</h2>
              <p>
                I developed a comprehensive weather application leveraging the
                capabilities of React.js. This application is specifically
                designed to provide real-time weather updates to its users.
              </p>
              <h3>Key Features and Technologies:</h3>
              <ul>
                <li>
                  <strong>React.js:</strong> Utilized for creating the
                  application's interactive UI.
                </li>
                <li>
                  <strong>Open Weather Map API:</strong> Integrated to provide
                  accurate and up-to-date weather information.
                </li>
                <li>
                  <strong>Axios:</strong> Employed to implement the Open Weather
                  Map API, enabling effective communication with the server and
                  handling of server responses.
                </li>
              </ul>
              <p>
                This weather application project underscores my proficiency in
                React.js and API integration, as well as my capabilities in
                creating user-friendly and functional digital solutions.
              </p>
              <button
                onClick={() => {
                  window.open("https://weather-app-47046.web.app/", "_blank");
                }}
                className="website-button"
              >
                Visit Weather Website
              </button>
            </div>
          </div>
          <button
            className="closeModalButton"
            onClick={() => setModal2IsOpen(false)}
          >
            Close Modal
          </button>
        </Modal>
      </div>
      <div className="Weather-App" onClick={() => openModal(3)}>
        <div className="Info">
          <img src={require("../pics/nologo.png")} className="fh" />
          <div className="wApp">Research</div>
        </div>
        <Modal isOpen={modal3IsOpen} className="myStyledModal">
          <img src={require("../pics/nologo.png")} className="fh" />
          <div className="wDescription">
            <div>
              <h2>
                Undergraduate Computational Research Assistant in the Matsen Lab
                at Fred Hutch
              </h2>
              <p>
                <strong>Duration:</strong> July 2023 - Present
              </p>
              <p>
                As an undergraduate research assistant at the Matsen Lab, I
                explore the evolutionary dynamics of SARS-CoV-2's escape from
                thousands of antibodies. My work involves utilizing various
                machine learning models for pattern and cluster identification.
                By uncovering these patterns, I validate data and gain deeper
                insights into the evolutionary dynamics of COVID-19.
              </p>
              <h3>Tools and Technologies Employed:</h3>
              <ul>
                <li>
                  <strong>scikit-learn:</strong> Utilized various algorithms
                  like K-means, DBSCAN, HDBSCAN, and UMAP dimensionality
                  reduction for data processing, organization, and
                  interpretation.
                </li>
                <li>
                  <strong>Pandas:</strong> For data organization and processing.
                </li>
                <li>
                  <strong>Matplotlib & Seaborn:</strong> For data visualization
                  and interpretation.
                </li>
                <li>
                  <strong>Jupyter Notebook:</strong> For code organization and
                  reproducibility.
                </li>
              </ul>
              <p>Research paper currently in progress</p>
              <button
                onClick={() => {
                  window.open("https://matsen.fhcrc.org/", "_blank");
                }}
                className="website-button"
              >
                Visit Matsen Lab at Fred Hutch
              </button>
            </div>
          </div>
          <div>----------------------------------------------</div>
          <img src={require("../pics/isb2.png")} className="isb" />
          <div className="wDescription">
            <div>
              <h2>
                Computational Biology Internship at Institute for Systems
                Biology (ISB)
              </h2>
              <p>
                <strong>Duration:</strong> July 2022 - August 2022
              </p>
              <p>
                During my tenure as an intern, I was privileged to develop
                intricate computational tools aimed at revolutionizing cancer
                patient data analytics. A key contribution was the creation of
                comprehensive knowledge graphs that served as a conduit,
                bridging the intricate relationships between cancer and gene
                expressions in patients.
              </p>
              <h3>Tools and Technologies Employed:</h3>
              <ul>
                <li>
                  <strong>Neo4j:</strong> For graph database construction,
                  ensuring efficient management and querying of complex data.
                </li>
                <li>
                  <strong>Cypher Query Language:</strong> Employed to interact
                  with the Neo4j database, facilitating precise data retrieval.
                </li>
                <li>
                  <strong>FastAPI:</strong> Utilized for rapid, robust, and
                  easy-to-use API creation.
                </li>
                <li>
                  <strong>Python:</strong> Leveraged for FastAPI and database
                  queries, boosting efficiency and simplicity.
                </li>
              </ul>
              <p>
                Through the integration of these powerful tools, we were able to
                provide deep and actionable insights into the intricate
                mechanisms of cancer, paving the way for more personalized and
                effective treatment strategies.
              </p>
              <h3>Skills Acquired and Utilized:</h3>
              <p>
                Python (Programming Language), Neo4j, Cypher Query Language,
                Graph Databases, FastAPI
              </p>
              <button
                onClick={() => {
                  window.open("https://kg-api-web.web.app/", "_blank");
                }}
                className="website-button"
              >
                Visit Isb Intern Project Website
              </button>
            </div>
          </div>
          <button
            className="closeModalButton"
            onClick={() => setModal3IsOpen(false)}
          >
            Close Modal
          </button>
        </Modal>
      </div>
    </div>
  );
};

export default Projects;
