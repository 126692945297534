import React from "react";
import { useState } from "react";
import "./Buttons.css";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-modal";
const Buttons = () => {
  const [modal1IsOpen, setModal1IsOpen] = useState(false);
  const openModal = (num) => {
    if (num === 1) {
      setModal1IsOpen(!modal1IsOpen);
    }
  };
  return (
    <div className="B-container">
      <div className="buttons">
        <button
          className="resumeButton"
          onClick={() => {
            window.open(
              "https://drive.google.com/file/d/1YdOMbj-tob8mvEVtlK1h_6Yru6whTDhS/view?usp=sharing",
              "_blank"
            );
          }}
        >
          Resume
        </button>

        <button
          onClick={() => {
            window.open(
              "https://www.linkedin.com/in/omar-abdel-aziz-b311b9225/",
              "_blank"
            );
          }}
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </button>
        <button
          onClick={() => {
            window.open("https://github.com/omar-abdel-aziz", "_blank");
          }}
        >
          <FontAwesomeIcon icon={faGithub} />
        </button>

        <button onClick={() => openModal(1)}>
          <FontAwesomeIcon icon={faPhone} />
          <Modal isOpen={modal1IsOpen} className="myStyledModal">
            <div className="Contact-Info">
              <h2>Contact Information</h2>
              <p>Name: Omar Abdel Aziz</p>
              <p>Email: oazizwork@gmail.com</p>
              <p>Phone: +1 (425) 236-7746</p>

              <h3>Social Media</h3>
              <p>LinkedIn: https://www.linkedin.com/in/abdel-aziz-omar/</p>
              <p>GitHub: https://github.com/omar-abdel-aziz</p>
            </div>

            <button
              className="closeModalButton"
              onClick={() => setModal1IsOpen(false)}
            >
              Close Modal
            </button>
          </Modal>
        </button>
      </div>
    </div>
  );
};

export default Buttons;
