import {
  faHome,
  faCode,
  faScroll,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import Button from "./Button/Button.js";
import Home from "./NewWebsite/Home";
import Info from "./Info";
import NavBar from "./NavBar";
import Projects from "./Projects";
import ContactInfo from "./ContactInfo";
import Feedback from "./feedback";
function App() {
  return (
    <div className="App">
      {/* <Router>
        <NavBar className="navbar" />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/info" element={<Info />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<ContactInfo />} />
          <Route path="/feedback" element={<Feedback />} />
        </Routes>
      </Router> */}
      <Home />
    </div>
  );
}
// useNavigate cant be used outside of router

export default App;
